import React from "react";
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Photos from "./components/Photos";
import Home from "./components/Home";
import Posts from "./components/Posts";
import PostDetail from "./components/PostDetail";
import Login from "./components/Admin/Login";
import AddPost from "./components/Admin/AddPost";
import Contact from "./components/Contact";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/photos",
        element: <Photos/>,
    },
    {
        path: "/posts",
        element: <Posts/>,
    },
    {
        path: "/post/:slug",
        element: <PostDetail/>,
    },
    {
        path: "/contact",
        element: <Contact/>,
    },
    {
        path: "/hahaha",
        children: [
            {
                path: "login",
                element: <Login/>,
            },
            {
                path: "addPost",
                element: <AddPost/>,
            }
        ]
    }
]);


const defaultTheme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#000000',
            dark: '#002884',
            contrastText: '#fff',
        },
    },
});

defaultTheme.typography.h3 = {
    fontSize: '1.5rem',
    '@media (min-width:600px)': {
        fontSize: '1.8rem',
    },
    [defaultTheme.breakpoints.up('md')]: {
        fontSize: '2.5rem',
    },
};

const RootComponent = () => (
    <ThemeProvider theme={defaultTheme}>
        <RouterProvider router={router}/>
    </ThemeProvider>
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent/>);

import Header from "./Header";
import * as React from "react";
import axios from "axios";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Loader from "./Loader";
import InnerHTML from 'dangerously-set-html-content'

function PostContent() {
    const [post, setPost] = React.useState({})
    const [loading, setLoading] = React.useState(true)
    const [headerGapWidth, setHeaderGapWidth] = React.useState(2000);

    React.useEffect(() => {
        const getPost = async () => {
            setLoading(false)
            try {
                const backendUrl = process.env.REACT_APP_BACKEND_URL;
                const response = await axios.get(backendUrl+`/api/post/getPostBySlug/${(window.location.href.split('/').pop() || window.location.href.split('/').pop())}`)
                setPost(response.data)
                setLoading(false)
            } catch (error) {
                console.log(error)
            }
        }
        getPost()
    }, [])

    React.useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 600) {
                setHeaderGapWidth(document.getElementById('header_gap').offsetWidth * 1.2);
            } else {
                setHeaderGapWidth(document.getElementById('header_gap').offsetWidth * 1.4);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
    }, [])

    return (
        <Grid className={'main_body'} container item mt={"calc(45vmax / 12)"} mb={"calc(45vmax / 12)"} spacing={3} zIndex={10}>
            {loading ?
                <Loader/>
                :
                (<Grid container item alignItems="center" justifyContent="center" mt={"calc(45vmax / 16)"}>
                    <Grid item xs={12}>
                        <Typography variant={"h3"} fontFamily={'Cormorant Garamond'} fontWeight={"normal"}
                                    textAlign={"center"}>{post.title}</Typography>
                    </Grid>
                    <Grid item container justifyContent="center" xs={12} mt={10} >
                        <InnerHTML allowRerender={true} html={post.content || "<div>"} />
                    </Grid>
                </Grid>)
            }

        </Grid>
    )
}

function PostDetail() {
    // refresh page when back button is clicked
    window.onpopstate = function () {
        window.location.reload();
    }


    return (
        <Grid container p={"3vw"}>
            <Header/>
            <PostContent/>
        </Grid>
    )
}

export default PostDetail;
import React, {useState} from 'react';
import {FormControl, TextField, Button, Grid} from '@mui/material';
import RichTextEditor from "./RichTextEditor";
import axios from "axios";

const MyForm = () => {
    const [content, setContent] = useState('');
    const [formData, setFormData] = useState({
        title: '',
        tags: [],
        content: '',
        abstract: '',
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: name === 'tags' ? value.split(',') : value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formData);

        let config = {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token'),
            }
        }
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        axios.post(backendUrl + '/api/post/addPost', formData, config)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            label="Title"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            required
                            fullWidth
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            label="Tags"
                            name="tags"
                            value={formData.tags.join(',')}
                            onChange={handleChange}
                            fullWidth
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            label="Abstract"
                            name="abstract"
                            value={formData.abstract}
                            onChange={handleChange}
                            required
                            fullWidth
                            multiline
                            rows={2}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            label="Content"
                            name="content"
                            value={content}
                            onChange={handleChange}
                            required
                            fullWidth
                            multiline
                            rows={2}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <RichTextEditor setContent={setContent}/>
                </Grid>
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default MyForm;

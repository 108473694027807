import Header from "./Header";
import * as React from "react";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from "@mui/material/Typography";
import { CardActionArea } from '@mui/material';

const cardStyles = {
    height: 0,
    paddingTop: '56.25%', // 16:9,
    marginTop: '30'
};

function handleCardClick() {
    console.log('awaiting development')
}

function Album() {
    const [cardWidth, setCardWidth] = React.useState(6)

    React.useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900) {
                setCardWidth(12);
            } else {
                setCardWidth(6);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);

    }, [])

    return (
        <Grid className={'albums'} item xs={cardWidth} textAlign={'center'}>
            <Card sx={{boxShadow: 0}}>
                <CardActionArea
                    onClick={handleCardClick}
                >
                    <CardMedia
                        image="/images/3.jpg"
                        title="Lorem ipsum"
                        style={cardStyles}
                    />
                    <CardContent >
                        <Typography fontFamily={'Cormorant Garamond'} fontWeight={"normal"} gutterBottom variant="h5">
                            Under Construction
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
}

function Albums() {
    return (
        <Grid className={'main_body'} container item mt={"calc(45vmax / 12)"} mb={"calc(45vmax / 12)"} spacing={3}>
            <Album/>

        </Grid>
    )
}

function Photos() {
    return (
        <Grid container p={"3vw"}>
            <Header/>
            <Albums/>
        </Grid>
    )
}

export default Photos;
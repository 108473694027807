import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SimpleImageSlider from "react-simple-image-slider";
import Header from "./Header";
import axios from "axios";

const images = [
    {url: "/images/1.jpg"},
    {url: "/images/2.jpg"},
];

function CenterParagraph(params) {
    return (
        <Grid container item alignItems="center" justifyContent="center" mt={"calc(45vmax / 8)"}
              mb={"calc(45vmax / 8)"}>
            <Typography variant={"h3"} fontFamily={'Cormorant Garamond'} fontWeight={"normal"}
                        maxWidth={params.headerGapWidth} textAlign={"center"}>Hi, my name is Yixin. I write code, take
                photos, play guitar and football.</Typography>
        </Grid>
    )
}

function Gallery() {
    return (
        <Grid id={"gallery"} container item alignItems="center" justifyContent="center">
            <SimpleImageSlider
                width={window.innerWidth * 0.75}
                height={window.innerWidth * 0.5}
                images={images}
                showBullets={true}
                showNavs={true}
            />
        </Grid>
    )
}

function Home() {
    const [headerGapWidth, setHeaderGapWidth] = React.useState(1000);

    React.useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 600) {
                setHeaderGapWidth(document.getElementById('header_gap').offsetWidth * 0.6);
            } else {
                setHeaderGapWidth(document.getElementById('header_gap').offsetWidth * 1.5);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
    }, [])

    //spotify here todo
    // React.useEffect(() => {
    //     let backend_url = ''
    //     if (process.env.NODE_ENV === 'development') {
    //         backend_url = 'http://localhost:5000'
    //     } else {
    //         backend_url = 'https://yixinliu.net'
    //     }
    //   axios.get(backend_url+'/api/externalAPI/getCurrentSpotifySong')
    //     .then(response => {
    //       let data = response.data;
    //       let album_name = data.album_name;
    //       let album_image_url = data.album_image_url;
    //       let artist_name = data.artist_name;
    //       let song_url = data.song_url;
    //       let spotify_uri = data.spotify_uri;
    //       let song_id = data.song_id;
    //
    //       if (song_id !== '') {
    //         // $('.spinner').remove();
    //         // $('#spotify_player').attr('src', `https://open.spotify.com/embed/track/${song_id}`);
    //         // $('.spotify_playlist').hide();
    //         // $('.spotify_current_song').show();
    //
    //       } else {
    //         // $('.spinner').remove();
    //         // $('.spotify_current_song').hide();
    //         // $('.spotify_playlist').show();
    //       }
    //
    //       console.log(data)
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });
    // }, [])

    return (
        <Grid container p={"3vw"}>
            <Header/>
            <CenterParagraph headerGapWidth={headerGapWidth}/>
            <Gallery/>

        </Grid>
    )
}

export default Home;
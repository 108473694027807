import Header from "./Header";
import * as React from "react";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography"
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";

function ContactCard() {
    const [imageWidth, setImageWidth] = React.useState(5)
        React.useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900) {
                setImageWidth(12);
            } else {
                setImageWidth(5);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);

    }, [])
    const MailToMe = () => {
        return (
            <Link to='#' onClick={(e) => {
                e.preventDefault();
                window.location = 'mailto:yixinliu9923@gmail.com';
            }}>
                <Typography variant={"h7"} fontFamily={'Cormorant Garamond'}
                            fontWeight={"normal"}>
                    yixinliu9923@gmail.com
                </Typography>
            </Link>
        );
    };

    return (
        <Grid container item p={5}>
            <Grid item xs/>
            <Grid id={'contact_card'} container item mt={"calc(45vmax / 8)"} maxWidth={1400}>
                <Grid container item xs={7} mb={"calc(45vmax / 8)"}>
                    <Grid container item direction="column" justifyContent={'center'}
                          alignItems={'center'}>
                        <Grid container item direction="column" mb={"calc(45vmax / 16)"}>
                            <Typography variant={"h3"} fontFamily={'Cormorant Garamond'}
                                        fontWeight={"normal"}>Contact</Typography>
                        </Grid>
                        <Grid container item direction="column">
                            <Typography variant={"h6"} fontFamily={'Cormorant Garamond'}
                                        fontWeight={"bold"} mb={'0.2em'}>Email</Typography>
                            <MailToMe/>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container item xs={imageWidth} justifyContent={'center'}>
                    <Box
                        component="img"
                        src="/images/4.jpg"
                        alt={'Il Tevere'}
                        sx={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}
                        mb={'0.2em'}
                    />
                    <Typography variant={"body2"} fontFamily={'Cormorant Garamond'}
                                fontWeight={"normal"}>Il Fiume Tevere</Typography>
                </Grid>
            </Grid>
            <Grid item xs/>
        </Grid>
    )
}

function Contact() {
    return (
        <Grid container p={"3vw"}>
            <Header/>
            <ContactCard/>
        </Grid>
    )
}

export default Contact;


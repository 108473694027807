import React from 'react';
import './loader.css'
const Loader = () => {
  return (
     <div className="mask">
         <div className="loader"></div>
    </div>

  );
}

export default Loader;
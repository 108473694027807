import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MoreIcon from "@mui/icons-material/MoreVert";
import {Link} from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import "./header.css"

function Header() {
    const [mobileMenuId, _] = 'primary-menu-mobile'
    const [mainBodyHeight, setMainBodyHeight] = React.useState(0);
    const [showDrawer, setShowDrawer] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
        setShowDrawer(open);
        if (open) {
            let x = window.scrollX;
            let y = window.scrollY;
            window.onscroll = function () {
                window.scrollTo(x, y);
            };
        } else {
            window.onscroll = function () {
            };
        }
    }

    React.useEffect(() => {
        const handleResize = () => {
            if (document.getElementsByClassName('main_body')[0]) {
                setMainBodyHeight(window.innerHeight - document.getElementsByClassName('main_body')[0].offsetTop);
            }
            if (window.innerWidth > 600) {
                setShowDrawer(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);

    }, [])

    const MobileMenu = (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={mainBodyHeight}>
            <List>
                <ListItem>
                    <Link to={`/posts`} className='text-link' reloadDocument>
                        <Typography variant="h5"
                                    sx={{
                                        fontFamily: "'Cormorant Garamond'"
                                    }}>
                            POSTS
                        </Typography>
                    </Link>
                </ListItem>
                {/*<ListItem>*/}
                {/*    <Link to={`/photos`} className='text-link' reloadDocument>*/}
                {/*        <Typography variant="h5"*/}
                {/*                    sx={{*/}
                {/*                        fontFamily: "'Cormorant Garamond'"*/}
                {/*                    }}>*/}
                {/*            PHOTOS*/}
                {/*        </Typography>*/}
                {/*    </Link>*/}
                {/*</ListItem>*/}
                <ListItem>
                    <Link to={`/contact`} className='text-link' reloadDocument>
                        <Typography variant="h5"
                                    sx={{
                                        fontFamily: "'Cormorant Garamond'"
                                    }}>
                            CONTACT
                        </Typography>
                    </Link>
                </ListItem>
                <ListItem>
                    <IconButton size="large" color="inherit"
                                href="https://www.linkedin.com/in/yixin-liu-aa48b31b8/" target="_blank">
                        <LinkedInIcon/>
                    </IconButton>
                    <IconButton size="large" color="inherit" href="https://github.com/ihsinliu"
                                target="_blank">
                        <GitHubIcon/>
                    </IconButton>
                </ListItem>
            </List>
        </Box>
    )

    const renderMobileMenu = (
        <Drawer
            transitionDuration={1}
            variant={'persistent'}
            PaperProps={{
                //height should be the same as components of class name main_body
                sx: {height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', zIndex: 99}
            }}
            anchor={'bottom'}
            open={showDrawer}
            onClose={toggleDrawer(false)}
        >
            {MobileMenu}
        </Drawer>
    );

    return (
        <Grid id={"header"} container item>
            <Grid item xs></Grid>
            <Grid item container maxWidth={1600}>
                <Box sx={{flexGrow: 1}}>
                    <AppBar
                        position="relative"
                        color="default"
                        sx={{background: 'transparent', boxShadow: 'none', zIndex: 100}}
                    >
                        <Toolbar>
                            <Link to={`/`} className='text-link' reloadDocument>
                                <Typography variant="h5"
                                            sx={{
                                                fontFamily: "'Cormorant Garamond'"
                                            }}>
                                    Yixin Liu
                                </Typography>
                            </Link>
                            <Box id={"header_gap"} sx={{flexGrow: 1}}/>
                            <Box spacing={10} sx={{display: {xs: 'none', md: 'flex'}}}>
                                <Button disableRipple>
                                    <Link to={`/posts`} className='text-link' reloadDocument>
                                        <Typography variant="h7"
                                                    sx={{
                                                        fontFamily: "'Cormorant Garamond'"
                                                    }}>
                                            POSTS
                                        </Typography>
                                    </Link>
                                </Button>
                                {/*<Button disableRipple>*/}
                                {/*    <Link to={`/photos`} className='text-link' reloadDocument>*/}
                                {/*        <Typography variant="h7"*/}
                                {/*                    sx={{*/}
                                {/*                        fontFamily: "'Cormorant Garamond'"*/}
                                {/*                    }}>*/}
                                {/*            PHOTOS*/}
                                {/*        </Typography>*/}
                                {/*    </Link>*/}
                                {/*</Button>*/}
                                <Button disableRipple>
                                    <Link to={`/contact`} className='text-link' reloadDocument>
                                        <Typography variant="h7"
                                                    sx={{
                                                        fontFamily: "'Cormorant Garamond'"
                                                    }}>
                                            CONTACT
                                        </Typography>
                                    </Link>
                                </Button>
                                <IconButton size="large" color="inherit"
                                            href="https://www.linkedin.com/in/yixin-liu-aa48b31b8/" target="_blank">
                                    <LinkedInIcon/>
                                </IconButton>
                                <IconButton size="large" color="inherit" href="https://github.com/yixinliu99"
                                            target="_blank">
                                    <GitHubIcon/>
                                </IconButton>
                            </Box>
                            <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                                <IconButton
                                    size="large"
                                    aria-label="show more"
                                    aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={toggleDrawer(!showDrawer)}
                                    color="inherit"
                                >
                                    <MoreIcon/>
                                </IconButton>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    {renderMobileMenu}
                </Box>
            </Grid>
            <Grid item xs></Grid>
        </Grid>
    )
}

export default Header;
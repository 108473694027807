import Header from "./Header";
import * as React from "react";
import axios from "axios";
import {v4 as uuidv4} from 'uuid';
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {CardActionArea} from '@mui/material';
import {useNavigate} from "react-router-dom";
import Loader from "./Loader";
import Box from "@mui/material/Box";


function Post(params) {
    const [cardWidth, setCardWidth] = React.useState(4)
    const navigate = useNavigate();

    function handleCardClick(slug) {
        navigate(`/post/${slug}`)
    }

    React.useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900) {
                setCardWidth(12);
            } else {
                setCardWidth(3);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);

    }, [])

    return (
        <Grid item xs={cardWidth}>
            <Card>
                <CardActionArea
                    onClick={() => {
                        handleCardClick(params.post.slug)
                    }}
                >
                    <CardContent>
                        <Typography fontFamily={'Cormorant Garamond'} fontWeight={"normal"} gutterBottom variant="h5">
                            {params.post.title}
                        </Typography>
                        <Box sx={{mb: 1.5}} color="text.secondary">
                            {params.post.tags && params.post.tags.map((tag, index) => {
                                return (
                                    <Typography key={uuidv4()} variant="body2">
                                        {tag.name}
                                    </Typography>
                                )
                            })}
                        </Box>
                        <Typography variant="body2">
                            {params.post.abstract}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
}

function PostsGroup() {
    const [loading, setLoading] = React.useState(true)
    const [posts, setPosts] = React.useState([])

    React.useEffect(() => {
        const getPosts = async () => {

            setLoading(true)
            try {
                const backendUrl = process.env.REACT_APP_BACKEND_URL;
                const {data} = await axios.get((backendUrl + '/api/post/getAllPosts'))

                setPosts(data.posts)
                setLoading(false)
            } catch (error) {
                setLoading(true)
                console.log(error)
            }
        }
        getPosts()
    }, [])
    return (
        <Grid className={'main_body'} container item mt={"calc(45vmax / 12)"} mb={"calc(45vmax / 12)"} spacing={2}>
            <Grid item xs></Grid>
            <Grid item container maxWidth={1600}>
                {loading ?
                    <Loader/>
                    :
                    posts.map((post) => {
                        return (
                            <Post key={uuidv4()} post={post}/>
                        )
                    })
                }
            </Grid>
            <Grid item xs></Grid>

        </Grid>
    )
}

function Posts() {
    return (
        <Grid container p={"3vw"}>
            <Header/>
            <PostsGroup/>
        </Grid>
    )
}

export default Posts;